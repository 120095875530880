<template>
	<div class="atlas">
		<el-dialog :title="title" @close="handleClose" v-if="dialogVisible" :visible.sync="dialogVisible"
			:fullscreen="fullscreen">
			<div class="container flex" v-loading="loading" element-loading-text="拼命加载中"
				element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
				<div class="time" ref="chatsLeft"
					style="display: flex;flex-direction: column;width:350px;height:calc(100vh - 60px) ">
					<div class="flex" v-if="!isTendency" style="height: 43px;line-height: 25px;margin-left: 3px;">
						<el-checkbox-group v-model="checkList" @change="checkChang">
							<el-checkbox label="0">正常</el-checkbox>
							<el-checkbox label="1">警告</el-checkbox>
						</el-checkbox-group>

						<el-button @click="changeTime" size="mini" class="getTableBtn"
							v-if="typeName == '多时域波形' || typeName == '多频谱分析' || typeName == '瀑布图'">
							选中对比
						</el-button>
					</div>
					<!-- <el-radio-group v-model="timeMode" size="mini" style="    padding: 10px 10px 0;"
						@change="changeTimeMode" v-if="typeName == '温度'||typeName == '转速'">
						<el-radio-button :label="1">概要模式</el-radio-button>
						<el-radio-button :label="2">详细查询</el-radio-button>
					</el-radio-group> -->
					<div v-if="timeMode == 1">
						<div class="quickTime" v-if="isTendency">
							<div class="quickTime-item" :class="currentQuickTime == 0 ? 'is-active' : ''"
								@click="selectQuickTime(0)">今日</div>
							<div class="quickTime-item" :class="currentQuickTime == 1 ? 'is-active' : ''"
								@click="selectQuickTime(1)">最近7天</div>
							<div class="quickTime-item " :class="currentQuickTime == 2 ? 'is-active' : ''"
								@click="selectQuickTime(2)">最近30天</div>
						</div>
						<el-date-picker v-model="value1" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss"
							:append-to-body="false" popper-class="datePicker" range-separator="至"
							start-placeholder="开始日期" end-placeholder="结束日期" @change="timeChang"></el-date-picker>
						<div style="margin: 0 10px 5px;" v-if="typeName == '趋势分析'">查询时间最长不能超过12个月</div>
						<div style="margin: 0 10px 5px;" v-else-if="typeName == '特征分析'">查询时间最长不能超过60天</div>
						<!-- <div style="margin: 0 10px 5px;" v-else>查询时间最长不能超过7天</div> -->
					</div>

					<div class="flex-1" v-if="timeMode == 2">
						<el-date-picker v-model="time2" type="date" popper-class="datePicker" value-format="yyyy-MM-dd"
							:append-to-body="false" placeholder="选择日期" @change="timechange2"></el-date-picker>
					</div>

					<div class="flex-1" v-if="timeMode == 2">
						<el-table border ref="table" :data="secondTimeData" style="width: 100%;font-size: 10px;"
							row-key="id" @row-click="rowClick2" :row-style="rowClass" :height="tableHeight2">
							<el-table-column prop="time2" label="时间段"></el-table-column>
							<el-table-column prop="num" label="最高值"></el-table-column>
						</el-table>
					</div>

					<div class="timeBox flex-1" v-show="!isTendency" v-loading="tableLoading"
						element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
						element-loading-background="rgba(0, 0, 0, 0.8)">
						<el-table border ref="table" :data="timeData.records" style="width: 100%;font-size: 10px;"
							row-key="id" @selection-change="handleSelectionChange" @row-click="rowClick"
							:row-style="rowClass" :height="tableHeight">
							<el-table-column type="selection" align="center" width="40"></el-table-column>
							<el-table-column label="时间">
								<template slot-scope="scope">
									<div :style="{ color: scope.row.stat == 1 ? '#ffffffd9' : '#23e1ff' }">
										{{ scope.row.time }}
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="rms" label="总值" width="65"></el-table-column>
							<el-table-column label="状态" align="center" width="55">
								<template slot-scope="scope">
									<!-- <img v-if="scope.row.alarm == 0" src="@assets/osImg/jkdp/zhengchang.png" style="height: 18px;" />
									<img v-if="scope.row.alarm == 1" src="@assets/osImg/jkdp/jingbao1.gif" style="height: 18px;" />
									<img v-if="scope.row.alarm == 2" src="@assets/osImg/jkdp/jingbao2.gif" style="height: 18px;" />
									<img v-if="scope.row.alarm == 3" src="@assets/osImg/jkdp/jingbao3.gif" style="height: 18px;" /> -->
									<div
										style="display: flex;flex-direction: row;align-items: center;justify-content: center;">
										<img v-if="scope.row.alarm == 0" src="@assets/osImg/jkdp/zhengchang.png"
											style="height: 18px;" />
										<img v-if="scope.row.alarm == 1" src="@assets/osImg/jkdp/jingbao1.gif"
											style="height: 18px;" />
										<img v-if="scope.row.alarm == 2" src="@assets/osImg/jkdp/jingbao2.gif"
											style="height: 18px;" />
										<img v-if="scope.row.alarm == 3" src="@assets/osImg/jkdp/jingbao3.gif"
											style="height: 18px;" />
										<el-tooltip v-if="scope.row.stat == 0" class="item" effect="light"
											content="采集数据异常!" placement="top-start">
											<i class="el-icon-warning-outline"
												style="margin-left:3px; font-size: 18px;color:#ff5500;cursor: help;"></i>
										</el-tooltip>
									</div>
								</template>

							</el-table-column>
						</el-table>
						<el-pagination @current-change="handleCurrentChange" :current-page.sync="page.current"
							background :pager-count="5" :page-size="page.size" small layout="  pager,jumper"
							:total="timeData.total" v-show="!isTendency"></el-pagination>
					</div>
				</div>

				<div class="chats flex-1" style="overflow: hidden;">
					<div class="tabs ">
						<el-tabs class="bg99" v-model="activeName" type="border-card" @tab-click="handleClick">

							<el-tab-pane label="频谱分析" name="frequencySpectrum" v-if="cedianType == 1000">
								<pinpuChats :chatsData="chatsData" :activeMeasDef="activeMeasDef"
									v-if="activeName == 'frequencySpectrum'" @chatsChagne="chatsChagne"
									chartName="频谱分析"></pinpuChats>
							</el-tab-pane>
							<el-tab-pane label="特征分析" name="tezhengfenxi" v-if="cedianType == 1000">
								<tezhengfenxiChats :chatsData="chatsData" :activeMeasDef="activeMeasDef"
									v-if="activeName == 'tezhengfenxi'" @chatsChagne="chatsChagne"
									@tezhengTypesChange="tezhengTypesChange" @clickPoint="clickTempPoint"
									@tempBack="tempBack" :canBack="req.dim ? true : false"></tezhengfenxiChats>
							</el-tab-pane>
							<el-tab-pane label="时域波形" name="timeDomain" v-if="cedianType == 1000">
								<timeDomainsChats :chatsData="chatsData" :activeMeasDef="activeMeasDef"
									@chatsChagne="chatsChagne" v-if="activeName == 'timeDomain'" chartName="时域波形">
								</timeDomainsChats>
							</el-tab-pane>
							<el-tab-pane label="包络解调" name="envelope" v-if="cedianType == 1000">
								<envelopeChats :chatsData="chatsData" :activeMeasDef="activeMeasDef"
									v-if="activeName == 'envelope'" @chatsChagne="chatsChagne"></envelopeChats>
							</el-tab-pane>
							<el-tab-pane label="趋势分析" name="tendency" v-if="cedianType == 1000">
								<tendencyChats :chatsData="chatsData" :activeMeasDef="activeMeasDef"
									v-if="activeName == 'tendency'" @chatsChagne="chatsChagne"></tendencyChats>
							</el-tab-pane>
							<el-tab-pane label="波形再处理" name="waveform" v-if="cedianType == 1000">
								<envelopeChats :chatsData="chatsData" :activeMeasDef="activeMeasDef" tabName="波形再处理"
									v-if="activeName == 'waveform'" @chatsChagne="chatsChagne"></envelopeChats>
							</el-tab-pane>
							<el-tab-pane label="多时域波形" name="timeDomains" v-if="cedianType == 1000">
								<timeDomainsChats :chatsData="chatsData" :activeMeasDef="activeMeasDef"
									v-if="activeName == 'timeDomains'" @chatsChagne="chatsChagne" chartName="多时域波形">
								</timeDomainsChats>
							</el-tab-pane>
							<el-tab-pane label="多频谱分析" name="frequencySpectrums" v-if="cedianType == 1000">
								<timeDomainsChats :chatsData="chatsData" :activeMeasDef="activeMeasDef"
									v-if="activeName == 'frequencySpectrums'" @chatsChagne="chatsChagne"
									chartName="多频谱分析"></timeDomainsChats>
							</el-tab-pane>
							<el-tab-pane label="倒谱分析" name="cepstrum" v-if="cedianType == 1000">
								<timeDomainsChats :chatsData="chatsData" :activeMeasDef="activeMeasDef"
									v-if="activeName == 'cepstrum'" @chatsChagne="chatsChagne" chartName="倒谱分析">
								</timeDomainsChats>
							</el-tab-pane>
							<el-tab-pane label="交叉相位" name="cross" v-if="cedianType == 1000">
								<crossChats :chatsData="chatsData" :activeMeasDef="activeMeasDef"
									v-if="activeName == 'cross'" :row="row" v-bind="$attrs" v-on="$listeners"
									@chatsChagne="chatsChagne"></crossChats>
							</el-tab-pane>
							<el-tab-pane label="轴心轨迹" name="axes" v-if="cedianType == 1000">
								<axesChats :chatsData="chatsData" :activeMeasDef="activeMeasDef"
									v-if="activeName == 'axes'" :row="row" v-bind="$attrs" v-on="$listeners"
									@chatsChagne="chatsChagne"></axesChats>
							</el-tab-pane>
							<el-tab-pane label="相关分析" name="correlation" v-if="cedianType == 1000">
								<correlation :chatsData="chatsData" :activeMeasDef="activeMeasDef"
									v-if="activeName == 'correlation'" :row="row" v-bind="$attrs" v-on="$listeners"
									@chatsChagne="chatsChagne"></correlation>
							</el-tab-pane>
							<el-tab-pane label="阶次分析" name="degree" v-if="cedianType == 1000">
								<envelopeChats :chatsData="chatsData" :activeMeasDef="activeMeasDef" tabName="阶次分析"
									v-if="activeName == 'degree'" @chatsChagne="chatsChagne"></envelopeChats>
							</el-tab-pane>
							<el-tab-pane label="瀑布图" name="waterfall" v-if="cedianType == 1000">
								<waterfallChats :chatsData="chatsData" :activeMeasDef="activeMeasDef"
									v-if="activeName == 'waterfall'" @chatsChagne="chatsChagne"></waterfallChats>
							</el-tab-pane>
							<el-tab-pane label="转速" name="rpm" v-if="cedianType == 1001">
								<tempChats :chatsData="chatsData" @chatsChagne="chatsChagne" chartName="转速"
									v-if="activeName == 'rpm'" :isShowBtns="false" @clickPoint="clickTempPoint"
									@tempBack="tempBack" :canBack="req.dim ? true : false"></tempChats>
							</el-tab-pane>
							<el-tab-pane label="温度" name="temp" v-if="cedianType == 1002">
								<tempChats :chatsData="chatsData" @chatsChagne="chatsChagne" chartName="温度"
									v-if="activeName == 'temp'" :isShowBtns="false" @clickPoint="clickTempPoint"
									@tempBack="tempBack" :canBack="req.dim ? true : false"></tempChats>
							</el-tab-pane>
							<el-tab-pane label="含水量" name="water" v-if="cedianType == 1003">
								<tempChats :chatsData="chatsData" @chatsChagne="chatsChagne" chartName="含水量"
									v-if="activeName == 'water'" :isShowBtns="false" @clickPoint="clickTempPoint"
									@tempBack="tempBack" :canBack="req.dim ? true : false"></tempChats>
							</el-tab-pane>
							<el-tab-pane label="实际转速" name="rrpm" v-if="cedianType == 1004">
								<tempChats :chatsData="chatsData" @chatsChagne="chatsChagne" chartName="实际转速"
									v-if="activeName == 'rrpm'" :isShowBtns="false" @clickPoint="clickTempPoint"
									@tempBack="tempBack" :canBack="req.dim ? true : false"></tempChats>
							</el-tab-pane>
							<el-tab-pane label="实际电流" name="rlightning" v-if="cedianType == 1004">
								<tempChats :chatsData="chatsData" @chatsChagne="chatsChagne" chartName="实际电流"
									v-if="activeName == 'rlightning'" :isShowBtns="false" @clickPoint="clickTempPoint"
									@tempBack="tempBack" :canBack="req.dim ? true : false"></tempChats>
							</el-tab-pane>
							<el-tab-pane label="湿度" name="humidness" v-if="cedianType == 1006">
								<tempChats :chatsData="chatsData" @chatsChagne="chatsChagne" chartName="湿度"
									v-if="activeName == 'humidness'" :isShowBtns="false" @clickPoint="clickTempPoint"
									@tempBack="tempBack" :canBack="req.dim ? true : false"></tempChats>
							</el-tab-pane>
							<el-tab-pane label="振动" name="ruibangVib" v-if="cedianType == 1007">
								<tempChats :chatsData="chatsData" @chatsChagne="chatsChagne" chartName="振动"
									v-if="activeName == 'ruibangVib'" :isShowBtns="false" @clickPoint="clickTempPoint"
									@tempBack="tempBack" :canBack="req.dim ? true : false"></tempChats>
							</el-tab-pane>
						</el-tabs>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import timeDomainsChats from './timeDomains_Chats.vue';
	import crossChats from './crossChats.vue';
	import tempChats from './tempChats.vue';
	import axesChats from './axesChats.vue';
	import tendencyChats from './tendencyChats.vue';
	import tezhengfenxiChats from './tezhengfenxiChats.vue';
	import envelopeChats from './envelopeChats.vue';
	import waterfallChats from './waterfallChats.vue';
	import pinpuChats from './pinpuChats.vue';
	import correlation from './correlation.vue';
	export default {
		props: {
			row: {
				type: Object,
				default () {
					return {};
				}
			}
		},
		data() {
			return {
				tableHeight: 0,
				tableHeight2: 0,
				current: 1,
				size: 10,
				total: 100,
				isTendency: true,
				dialogVisible: false,
				fullscreen: true,
				title: '',
				url: '/backend-api/web/spec/vib/getTrend',
				chatsData: null,
				checkList: [],
				timeMode: 1,
				activeMeasDef: '',
				activeName: 'tendency',
				activeNameList: [
					// 时域波形
					{
						name: 'timeDomain',
						url: '/backend-api/web/spec/vib/get'
					},
					// 多时域波形
					{
						name: 'timeDomains',
						url: '/backend-api/web/spec/vib/get'
					},
					// 倒谱分析
					{
						name: 'cepstrum',
						url: '/backend-api/web/spec/vib/get'
					},
					// 频谱分析
					{
						name: 'frequencySpectrum',
						url: '/backend-api/web/spec/vib/get'
					},
					//多频谱分析
					{
						name: 'frequencySpectrums',
						url: '/backend-api/web/spec/vib/get'
					},
					// 轴心轨迹
					{
						name: 'axes',
						url: '/backend-api/web/spec/vib/get'
					},
					// 相关分析
					{
						name: 'correlation',
						url: '/backend-api/web/spec/vib/get'
					},
					//  交叉相位
					{
						name: 'cross',
						url: '/backend-api/web/spec/vib/get'
					},
					//特征分析
					{
						name: 'tezhengfenxi',
						url: '/backend-api/web/spec/vib/get'
					},
					//趋势分析
					{
						name: 'tendency',
						url: '/backend-api/web/spec/vib/get'
					},
					//包络解调
					{
						name: 'envelope',
						url: '/backend-api/web/spec/vib/get'
					},
					// 波形再处理
					{
						name: 'waveform',
						url: '/backend-api/web/spec/vib/get'
					},
					// 阶次分析
					{
						name: 'degree',
						url: '/backend-api/web/spec/vib/get'
					},
					// 瀑布图
					{
						name: 'waterfall',
						url: '/backend-api/web/spec/vib/get'
					},
					// 转速
					{
						name: 'temp',
						url: '/backend-api/web/spec/rpm'
					},
					// 温度
					{
						name: 'temp',
						url: '/backend-api/web/spec/temp'
					},
					// 含水量
					{
						name: 'water',
						url: '/backend-api/web/spec/oil'
					},
					// 实际转速
					{
						name: 'rrpm',
						url: '/backend-api/web/spec/rpm/wincc'
					},
					// 实际电流
					{
						name: 'rlightning',
						url: '/backend-api/web/spec/rpm/wincc'
					},
					// 湿度
					{
						name: 'humidness',
						url: '/backend-api/web/spec/getRuiBangShiDu'
					},
					// 瑞邦振动
					{
						name: 'ruibangVib',
						url: '/backend-api/web/spec/getRuiBangVib'
					}
				],
				show: true,
				value1: '',
				time2: '',
				timeData: {
					records: []
				},
				secondTimeData: [],
				timeList: [],
				mulTimeList: [],
				timeListString: '',
				form: {
					meas_id: '',
					meas_def: ''
				},
				page: {
					current: 1,
					size: 20
				},
				currentQuickTime: 1,
				tableLoading: false,
				loading: false,
				cedianType: '',
				typeName: '频谱分析', //当前选择的图谱类型名字，默认是第一个图表的名称
				measId: '',
				tempDim: 'd',
				req: {},
				tezhengTypes: ['有效值']
			};
		},

		watch: {
			row: {
				handler(val) {
					if(val){
						this.init(val)
					}
				},
				immediate: true,
			},
		},
		components: {
			timeDomainsChats,
			crossChats,
			tempChats,
			axesChats,
			tezhengfenxiChats,
			envelopeChats,
			tendencyChats,
			waterfallChats,
			pinpuChats,
			correlation
		},
		mounted() {
			// this.$watch('value', {
			//         handler(newVal, oldVal) {
			//             // 处理逻辑
			//         },
			//         immediate: true,
			//         deep: true
			//     });
		},
		beforeDestory() {
			window.onresize = null;
		},
		methods: {
			//初始化请求和处理
			init(val) {
				this.form = {
					meas_id: '',
					meas_def: '',
					time: ''
				};
				this.page = {
					current: 1,
					size: 20
				};
				this.tezhengTypes = ['有效值'];
				this.form.meas_id = val.meas_id;
				this.measId = val.meas_id;
				this.form.meas_def = val.name;
				this.activeMeasDef = val.name;
				this.cedianType = val.type;
				this.timeList = [];
				this.mulTimeList = [];
				this.timeMode = 1;
				this.timeListString = '';
				if (val.time) {
					this.form.time = val.time;
					this.timeListString = val.time;
				}
				this.value1 = [];
				this.currentQuickTime = 1;
				this.time2 = '';
				this.secondTimeData = [];
				this.tempDim = 'd';
				this.req = {};
				switch (val.type) {
					case 1001:
						//转速
						this.activeName = 'rpm';
						this.typeName = '转速';
						this.isTendency = true;
						this.url = '/backend-api/web/spec/rpm';
						break
					case 1002:
						//温度
						this.activeName = 'temp';
						this.typeName = '温度';
						this.isTendency = true;
						this.url = '/backend-api/web/spec/temp';
						break
					case 1003:
						//油液
						this.activeName = 'water';
						this.typeName = '含水量';
						this.isTendency = true;
						this.url = '/backend-api/web/spec/oil';
						break
					case 1004:
						//转速和电流
						switch (val.name) {
							case '实际转速':
								this.activeName = 'rrpm';
								this.typeName = '实际转速';
								break
							case '实际电流':
								this.activeName = 'rlightning';
								this.typeName = '实际电流';
								break
							default:
								this.activeName = 'rrpm';
								this.typeName = '实际转速';
						}
						this.isTendency = true;
						this.url = '/backend-api/web/spec/rpm/wincc';
						break
					case 1006:
						//湿度
						this.activeName = 'humidness';
						this.typeName = '湿度';
						this.isTendency = true;
						this.url = '/backend-api/web/spec/getRuiBangShiDu';
						break
					case 1007:
						//振动瑞邦
						this.activeName = 'ruibangVib';
						this.typeName = '振动(瑞邦)';
						this.isTendency = true;
						this.url = '/backend-api/web/spec/getRuiBangVib';
						break
					default:
						this.activeName = 'frequencySpectrum';
						this.typeName = '频谱分析';
						this.isTendency = false;
						this.url = '/backend-api/web/spec/vib/get';
				}
				this.getChats(this.url, this.form);
				this.getTimeList();
				this.$nextTick(() => {
					this.tableHeight = this.$refs.chatsLeft.offsetHeight - 170;
					this.tableHeight2 = this.$refs.chatsLeft.offsetHeight - 110;
					this.$refs.table.doLayout(); //更新表格 防止表格内容变化表格异常情况
					// 监听窗口大小变化
					window.onresize = () => {
						this.tableHeight = this.$refs.chatsLeft.offsetHeight - 170;
						this.tableHeight2 = this.$refs.chatsLeft.offsetHeight - 110;
						this.$refs.table.doLayout();
					};
				});
			},




			//温度图谱点击图谱的点
			tempBack() {
				if (this.req.dim) {
					if (this.req.dim == 'd') {
						this.getChats(this.url, this.form);
						this.req = {};
					} else {
						this.getTempDimData(this.req);
					}
				}
			},
			//温度图谱点击图谱的点
			clickTempPoint(time, measDef) {
				if (this.typeName == '湿度') return
				if (this.typeName == '振动(瑞邦)') return
				if (this.tempDim != '') {
					let param = {
						meas_id: this.measId,
						dim: this.tempDim,
						time: time
					};
					if (measDef) {
						param.meas_def = measDef;
					}
					this.getTempDimData(param);
				}
			},
			//温度图谱查询具体时间
			getTempDimData(param) {
				this.loading = true;
				let url = '';

				switch (this.typeName) {
					case '特征分析':
						url = '/backend-api/web/spec/vib/getDimFeatureAnalysis';
						param.types = this.tezhengTypes.join(',');
						break;
					case '温度':
						url = '/backend-api/web/spec/temp/dim';
						break;
					case '转速':
						url = '/backend-api/web/spec/rpm/dim';
						break;
					case '含水量':
						url = '/backend-api/web/spec/oil/dim';
						break;
					case '实际转速':
					case '实际电流':
						url = '/backend-api/web/spec/rpm/wincc/dim';
						param.type = this.typeName;
						break;
					default:
						url = '/backend-api/web/spec/temp/dim';
				}
				this.$get(url, param).then(res => {
					if (res.code == 1000) {
						this.tempDim = res.data.dim;
						this.req = res.data.req;
						res.data.length = 1;
						this.chatsData = res;
						this.title = res.data.root;
						this.loading = false;
					} else {
						this.$message.error(res.msg);
						this.loading = false;
					}
				});
			},
			//详细查询模式选择时间
			timechange2() {
				if (this.typeName == '温度') {
					this.getWenduTimeList();
				} else if (this.typeName == '转速') {
					this.getZhuansuTimeList();
				} else if (this.typeName == '含水量') {
					this.getYouyeTimeList();
				}
			},
			//选择时间段
			rowClick2(row, index, e) {
				if (this.typeName == '温度') {
					this.getChats('/backend-api/web/spec/temp/dtl', {
						meas_id: this.row.meas_id,
						time: row.time
					});
				} else if (this.typeName == '转速') {
					this.getChats('/backend-api/web/spec/rpm/dtl', {
						meas_id: this.row.meas_id,
						time: row.time
					});
				} else if (this.typeName == '含水量') {
					this.getChats('/backend-api/web/spec/oil/dtl', {
						meas_id: this.row.meas_id,
						time: row.time
					});
				}
			},
			//改变时间模式
			changeTimeMode() {
				if (this.timeMode == 1) {
					if (this.typeName == '温度') {
						this.url = '/backend-api/web/spec/temp';
						this.getChats(this.url, this.form);
					} else if (this.typeName == '转速') {
						this.url = '/backend-api/web/spec/rpm';
						this.getChats(this.url, this.form);
					} else if (this.typeName == '含水量') {
						this.url = '/backend-api/web/spec/oil';
						this.getChats(this.url, this.form);
					}
				}
			},
			//温度到秒的查询
			getWenduTimeList() {
				this.$get('/backend-api/web/spec/temp/hist', {
					time: this.time2,
					meas_id: this.row.meas_id
				}).then(res => {
					if (res.code == 1000) {
						this.secondTimeData = res.data;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			//转速到秒的查询
			getZhuansuTimeList() {
				this.$get('/backend-api/web/spec/rpm/hist', {
					time: this.time2,
					meas_id: this.row.meas_id
				}).then(res => {
					if (res.code == 1000) {
						this.secondTimeData = res.data;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			//含水量到秒的查询
			getYouyeTimeList() {
				this.$get('/backend-api/web/spec/oil/hist', {
					time: this.time2,
					meas_id: this.row.meas_id
				}).then(res => {
					if (res.code == 1000) {
						this.secondTimeData = res.data;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			//对所选行进行样式设置
			rowClass({
				row,
				rowIndex
			}) {
				switch (this.typeName) {
					case '特征分析':
						break;
					case '趋势分析':
						break;
					case '阶次分析':
					case '波形再处理':
					case '交叉相位':
					case '包络解调':
					case '倒谱分析':
					case '频谱分析':
					case '时域波形':
					case '轴心轨迹':
						if (this.timeList.includes(row)) {
							return {
								'background-color': '#2B343E !important'
							};
						}
						break;
					case '多频谱分析':
					case '瀑布图':
					case '多时域波形':
						if (this.mulTimeList.includes(row)) {
							return {
								'background-color': '#2B343E !important'
							};
						}
						break;
				}
			},
			//分页选择页面回调
			handleCurrentChange(val) {
				this.page.current = val;
				this.getTimeList();
			},
			//趋势分析选择快捷时间
			selectQuickTime(type) {
				this.currentQuickTime = type;
				// $(".quickTime .quickTime-item").removeClass("is-active");
				// $(".quickTime .quickTime-item").eq(type).addClass("is-active");
				let time = {
					opt: type
				};
				this.getChats(this.url, Object.assign(this.form, time));
			},
			//点击行回调
			rowClick(row, index, e) {
				this.$refs.table.toggleRowSelection(row);
			},
			// 时间列表
			getTimeList() {
				if (
					this.typeName == '趋势分析' ||
					this.typeName == '特征分析' ||
					this.typeName == '转速' ||
					this.typeName == '温度' ||
					this.typeName == '含水量' ||
					this.typeName == '实际转速' ||
					this.typeName == '实际电流'
				) {} else {
					this.tableLoading = true;
					this.$get('/backend-api/web/spec/vib/listHist', Object.assign(this.page, this.form)).then(res => {
						if (res.code == 1000) {
							this.timeData = []
							this.timeData = res.data;
							this.tableLoading = false;
						} else {
							this.$message.error(res.msg);
							this.tableLoading = false;
						}
					});
				}
			},
			// 图表内切换参数
			chatsChagne(val, otherValObj) {
				this.activeMeasDef = val;
				this.form.meas_def = val;
				this.req = {};
				if (otherValObj) {
					this.getTimeList();
					this.getChats(this.url, Object.assign({}, this.form, otherValObj));
				} else {
					this.getTimeList();
					this.getChats(this.url, this.form);
				}
			},
			// 根据时间获取列表
			timeChang() {
				//最多选择七天
				let betDay = new Date(this.value1[1]) / (1000 * 60 * 60 * 24) - new Date(this.value1[0]) / (1000 * 60 *
					60 * 24);
				if (this.typeName == '趋势分析') {
					if (betDay > 366) {
						this.value1 = [];
						this.$message.error('最多选择12个月');
						return;
					}
				} else if (this.typeName == '特征分析') {
					if (betDay > 60) {
						this.value1 = [];
						this.$message.error('最多选择60天');
						return;
					}
				} else {
					// if (betDay > 7) {
					// 	this.value1 = [];
					// 	this.$message.error("最多选择7天");
					// 	return;
					// }
				}
				if (
					this.typeName == '趋势分析' ||
					this.typeName == '特征分析' ||
					this.typeName == '转速' ||
					this.typeName == '温度' ||
					this.typeName == '含水量' ||
					this.typeName == '实际转速' ||
					this.typeName == '实际电流'
				) {
					$('.quickTime .quickTime-item').removeClass('is-active');
					let time = {
						opt: 3,
						begin_time: this.value1[0],
						end_time: this.value1[1]
					};
					this.getChats(this.url, Object.assign(this.form, time));
				} else {
					let form1 = {
						begin_time: '',
						end_time: ''
					};
					if (this.value1) {
						form1 = {
							begin_time: this.value1[0],
							end_time: this.value1[1]
						};
					}
					this.form = Object.assign(this.form, form1);
					this.getTimeList();
				}
			},
			// 选中对比
			changeTime() {
				let time = {};
				if (this.typeName == '多时域波形' || this.typeName == '多频谱分析' || this.typeName == '瀑布图') {
					time = {
						times: this.timeListString
					};
					if (this.timeList.length > 6) {
						this.$message.error('最多选择6条');
						return;
					}
				} else {
					time = {
						time: this.timeListString
					};
				}
				this.form = Object.assign(this.form, time);
				this.getChats(this.url, this.form);
			},

			// 表格点击行
			handleSelectionChange(val) {
				switch (this.typeName) {
					case '特征分析':
						break;
					case '趋势分析':
						break;
					case '阶次分析':
					case '波形再处理':
					case '交叉相位':
					case '包络解调':
					case '倒谱分析':
					case '频谱分析':
					case '时域波形':
					case '轴心轨迹':
						if (val.length > 1) {
							this.$refs.table.clearSelection();
							this.$refs.table.toggleRowSelection(val.pop());
						} else if (val.length == 1) {
							this.timeList = val;
							this.timeListString = this.timeList
								.map(i => {
									return i.time;
								})
								.join(',');
							this.changeTime();
						}
						break;
					case '多频谱分析':
					case '瀑布图':
					case '多时域波形':
						this.mulTimeList = val;
						this.timeListString = this.mulTimeList
							.map(i => {
								return i.time;
							})
							.join(',');
						break;
				}
			},
			//  标签切换请求图谱数据
			handleClick(val) {
				this.value1 = [];
				this.value2 = [];
				if (
					val.label == '特征分析' ||
					val.label == '趋势分析' ||
					val.label == '转速' ||
					val.label == '温度' ||
					val.label == '含水量' ||
					val.label == '实际转速' ||
					val.label == '实际电流'
				) {
					this.isTendency = true;
				} else {
					this.isTendency = false;
				}
				this.activeNameList.forEach(i => {
					if (i.name == val.name) {
						this.url = i.url;
					}
				});
				if (val.label != '多时域波形' || val.label != '多频谱分析' || val.label != '瀑布图') {
					this.$nextTick(() => {
						this.typeName = val.label;
						this.getTimeList();
						this.$refs.table.clearSelection();
						if (this.timeList.length > 0) {
							this.$refs.table.toggleRowSelection(this.timeList[0]);
							this.timeListString = this.timeList
								.map(i => {
									return i.time;
								})
								.join(',');
						}
						this.changeTime();
					});
				} else {
					this.typeName = val.label;
					this.getTimeList();
				}
			},
			//  正常告警切换
			checkChang(val) {
				if (val.length == 2 || val.length == 0) {
					let timeFrom = {
						alarm: ''
					};
					this.form = Object.assign(this.form, timeFrom);
				} else {
					let timeFrom = {
						alarm: val[0]
					};
					this.form = Object.assign(this.form, timeFrom);
				}
				this.getTimeList();
			},
			//特征分析图谱显示类型切换
			tezhengTypesChange(types) {
				this.req = {};
				this.tezhengTypes = types;
				this.getChats('/backend-api/web/spec/vib/get', this.form);
			},

			//  请求图表数据
			getChats(val, param) {
				this.loading = true;
				let url = val;
				if (this.typeName == '实际转速' || this.typeName == '实际电流') {
					param.type = this.typeName;
				}
				if (this.typeName == '特征分析') {
					url = '/backend-api/web/spec/vib/getFeatureAnalysis';
					param.types = this.tezhengTypes
						.map(i => {
							return i;
						})
						.join(',');
				}
				param.type = this.typeName;
				this.$get(url, param).then(res => {
					this.loading = false;
					if (res.code != 1000) {
						this.$message.error(res.msg);
						return;
					}
					if (this.typeName == '特征分析') {
						this.tempDim = res.data.dim;
					}
					if (this.cedianType == 1001 || this.cedianType == 1002 || this.cedianType == 1003 || this
						.cedianType == 1004) {
						//温度和转速
						this.tempDim = res.data.dim;
					} else if (this.cedianType == 1003) {
						//油液
						res.data.xData = res.data.xData[0].data;
						res.data.yData1 = res.data.yData[0].data;
						res.data.length = 1;
					}
					this.chatsData = Object.assign(res, param);
					this.title = res.data.root;
					if (this.typeName != '特征分析') {
						this.tezhengTypes = ['有效值'];
					}
					if (res.data.request) {
						this.row.meas_id_b = res.data.request.meas_id_b;
					}
				});
			},
			handleClose() {
				this.dialogVisible = false;
				this.$emit('update-value', '')
				Object.assign(this.$data, this.$options.data())
			},
		}
	};
</script>

<style lang="scss" scoped>
	.dialogClass {
		background-color: #000b17;
	}

	.el-dialog__body {
		overflow: auto;
	}

	.atlas {
		::v-deep .el-dialog {
			background-color: rgba(0, 11, 23, 1);
		}

		::v-deep * {
			border-color: rgba(0, 11, 23, 1);
		}

		::v-deep .el-dialog__header,
		::v-deep .el-tabs__header {
			background-color: rgba(255, 255, 255, 0.15);
			border-color: rgba(255, 255, 255, 0.15);

			.el-dialog__headerbtn .el-dialog__close {
				color: #ffffff;
			}

			.el-dialog__headerbtn .el-dialog__close::before {
				background-color: #262f3a;
			}
		}

		::v-deep .el-radio-group {
			.el-radio-button:not(.is-active) .el-radio-button__inner {
				background-color: #161d28 !important;
				color: #2388ff !important;
				border-color: #2388ff !important;
			}
		}

		::v-deep .el-tabs__item {
			color: rgba(255, 255, 255, 0.85);
		}

		::v-deep .el-tabs__item.is-active {
			background-color: rgba(35, 136, 255, 1);
			border-color: rgba(35, 136, 255, 1);
			color: rgba(255, 255, 255, 1);
		}

		::v-deep .el-checkbox-group {
			margin-left: 10px;

			.el-checkbox__label {
				color: rgba(255, 255, 255, 0.85);
			}

			.el-checkbox__inner::after {
				background-color: #409eff !important;
			}
		}

		::v-deep .el-table::after,
		::v-deep .el-table::before {
			background-color: #141e29;
		}

		.quickTime {
			text-align: center;
			height: 43px;
			padding: 12px 20px 0 10px;
			display: flex;

			.quickTime-item {
				height: 24px;
				line-height: 22px;
				cursor: pointer;
				flex: 1;
				color: rgba(255, 255, 255, 0.65);
			}

			.is-active {
				background-color: rgba(255, 255, 255, 0.15);
				border-radius: 4px;
				padding: 1px;
			}
		}

		.time {
			background-color: #141e29;
		}

		.getTableBtn {
			margin-top: 10px;
			background-color: #141e29;
			color: #409eff;
			border-color: #409eff;
		}

		::v-deep .el-table--border th.gutter:last-of-type {
			border-bottom: none !important;
		}

		//滚动条
		*::-webkit-scrollbar {
			background-color: #1b2c45;
			width: 6px;
		}

		*::-webkit-scrollbar-thumb {
			background-color: #4b5259;
		}

		::v-deep .el-table {
			background-color: #141e29;
			color: rgba(255, 255, 255, 0.85);

			.el-table__row,
			.el-checkbox__inner {
				background-color: #141e29 !important;
				color: rgba(255, 255, 255, 0.85);
			}

			.el-table__body tr:hover>td {
				background-color: #1f2933 !important;
			}

			.el-table__body tr:hover>td>.cell {
				background-color: #1f2933 !important;
			}

			.el-table__body tr:hover>td>.cell>.el-checkbox {
				background-color: #1f2933 !important;
			}

			.el-table__body tr.current-row>td {
				background-color: #2b343e !important;
			}

			.el-table__body tr.current-row>td>.cell {
				background-color: #2b343e !important;
			}

			.el-table__body tr.current-row>td>.cell>.el-checkbox {
				background-color: #2b343e !important;
			}

			.el-table__header-wrapper * {
				background-color: #262f39 !important;
			}

			.el-checkbox__inner::after {
				background-color: #409eff !important;
			}

			.el-checkbox__input.is-checked .el-checkbox__inner {
				background-color: #409eff !important;
			}

			.el-checkbox__inner {
				border-color: #888888;
			}

			td,
			th.is-leaf {
				border-color: #262f39;
			}
		}

		::v-deep .el-pagination {

			button,
			.number,
			.more {
				background-color: #141e29;
				border: 1px solid #262f39;
				margin: 0 4px;
				color: rgba(255, 255, 255, 0.65);
			}
		}

		::v-deep .el-date-editor {
			border-color: rgba(255, 255, 255, 0.2);
			margin: 10px;

			.el-input__inner {
				border-color: rgba(255, 255, 255, 0.2);
			}

			.el-range-separator {
				color: rgba(255, 255, 255, 0.85);
			}
		}

		::v-deep .datePicker {
			background-color: #1f1f1f;

			* {
				border-color: rgba(255, 255, 255, 0.15);
				color: rgba(255, 255, 255, 0.85);
			}

			.el-time-panel {
				background-color: #1f1f1f;

				.el-scrollbar__bar {
					border-color: rgba(255, 255, 255, 0.08);
				}

				.el-time-panel__btn.confirm {
					background-color: #4a99f6;
					color: rgba(255, 255, 255, 1);
					border-color: #4a99f6;
					border-radius: 5px;
				}

				.el-time-panel__btn.cancel {
					border: #4a99f6;
					color: #4a99f6;
				}
			}

			.el-time-panel .el-time-spinner__item:not(.active):hover {
				background-color: #111d2c;
			}

			.el-input__inner,
			.el-picker-panel__footer {
				background-color: #1f1f1f;
			}

			.in-range div {
				background-color: #111d2c;
			}

			.el-button--default {
				background-color: #4a99f6;
				color: rgba(255, 255, 255, 1);
				border-color: #4a99f6;
			}

			.el-button--text {
				border: #4a99f6;

				* {
					color: #4a99f6;
				}
			}
		}

		.element::-webkit-scrollbar {
			width: 0 !important;
		}

		.el-table--border {
			overflow-y: auto;
		}

		::v-deep .el-dialog__title {
			color: #909399;
		}

		::v-deep .el-dialog__body {
			padding: 0;
		}

		::v-deep .el-pagination__jump {
			margin-left: 0px;
		}

		.container {
			overflow: hidden;

			.time {
				width: 400px;

				// background-color: #141e29;
				::v-deep .el-date-editor.el-input,
				::v-deep .el-date-editor.el-input__inner {
					width: 320px;
					background-color: #141e29;

					.el-input__inner {
						background-color: #141e29;
					}
				}

				::v-deep .el-date-editor .el-range-input {
					background-color: #141e29;
				}

				::v-deep .el-date-editor .el-range-separator {
					width: 20px;
				}

				.el-checkbox-group {
					padding-top: 13px;
					margin-right: 50px;
				}
			}
		}

		.r {
			color: red;
		}

		.g {
			color: green;
		}

		.y {
			color: yellow;
		}

		.bg99 {
			background-color: #000b17;
		}
	}
</style>